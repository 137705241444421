import {
  Container,
  Card,
  CardContent,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import { useContext, useRef, useEffect } from "react";
import { ReactTabulator } from "react-tabulator";
import History from "../../History";
import AddIcon from "@material-ui/icons/Add";
import SideBar from "./SideBar";

import { Context } from "../../Context";
import ErrorPage from "../ErrorPage";

const Vendors = () => {
  const tabulatorRef = useRef(null);
  const { state, getVendors, sortVendors } = useContext(Context);

  useEffect(() => {
    getVendors();
  }, [getVendors]);

  const columns = [
    { title: "ID", field: "id", width: 100 },
    {
      title: "Name",
      field: "name",
      headerFilter: true,
      headerFilterPlaceholder: "Filter by name...",
    },
  ];

  try {
    return (
      <SideBar>
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={(e) => History.push("/admin/vendors/create")}
            >
              Add New
            </Button>
            <Typography variant="h4">Vendors</Typography>
          </Box>
          <Card style={{ marginTop: "30px" }}>
            <CardContent>
              <ReactTabulator
                ref={tabulatorRef}
                columns={columns}
                layout={"fitColumns"}
                rowClick={(e, row) => {
                  History.push(`/admin/vendors/update/${row.getData().id}`);
                }}
                options={{
                  movableRows: true,
                  rowMoved: function (_) {
                    const rows = tabulatorRef.current.table.getRows(true);
                    let sortPayload = [];
                    for (let i = 0; i < rows.length; i++) {
                      const row = rows[i];
                      let id = row.getData().id;
                      let index = row.getPosition(false) + 1;
                      sortPayload.push({ id, index });
                    }

                    sortVendors({ variables: { sortPayload } });
                  },
                }}
                data={state.vendors}
              />
            </CardContent>
          </Card>
        </Container>
      </SideBar>
    );
  } catch (error) {
    console.log(error);
    return <ErrorPage></ErrorPage>;
  }
};
export default Vendors;
