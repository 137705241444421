import { Container, Box, Button } from "@material-ui/core";
import TopBar from "./public/TopBar";

function ErrorPage() {
  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <>
      <TopBar></TopBar>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <h1 style={{ textAlign: "center" }}>
            Ups... Something went wrong :(
          </h1>
          <Button onClick={reloadPage} href="/">
            Reload
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default ErrorPage;
