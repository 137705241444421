import { Container, Box } from "@material-ui/core";
import TopBar from "./TopBar";

function NotFound() {
  return (
    <>
      <TopBar></TopBar>
      <Container>
        <Box dislay="flex" justifyContent="center" alignItems="center">
          <h1 style={{ textAlign: "center" }}>404</h1>
        </Box>
      </Container>
    </>
  );
}

export default NotFound;
