import validator from "validator";

export const propertyValidation = (payload) => {
  let errs = {};
  if (validator.isEmpty(payload.name)) {
    errs.name = "Name is required";
  }
  if (validator.isEmpty(payload.type)) {
    errs.type = "Type is required";
  }
  if (
    !validator.isEmpty(payload.type) &&
    !validator.isIn(payload.type, ["select", "boolean", "number"])
  ) {
    errs.type = "Type must be number, select or boolean";
  }
  if (!validator.isEmpty(payload.type) && payload.type === "select") {
    if (payload.select.length <= 0) {
      errs.select = "Select field is required";
    }
  }
  return errs;
};

export const vendorValidation = (payload, props) => {
  let errs = {};
  if (validator.isEmpty(payload.name)) {
    errs.name = "Name is required";
  }
  if (validator.isEmpty(payload.website)) {
    errs.website = "Website is required";
  }
  if (validator.isEmpty(payload.logo)) {
    errs.logo = "Logo is required";
  }
  if (validator.isEmpty(payload.description)) {
    errs.description = "Description is required";
  }
  if (
    !validator.isEmpty(payload.website) &&
    !validator.isURL(payload.website, { require_protocol: true })
  ) {
    errs.website = "Website must be a valid url";
  }

  if (payload.plans.length > 0) {
    let planErros = [];
    for (let i = 0; i < payload.plans.length; i++) {
      let planErrs = {};
      let propertiesObj = {};
      if (validator.isEmpty(payload.plans[i].name)) {
        planErrs.name = "Plan name is required";
      }
      if (payload.plans[i].unkownPrice === false) {
        if (validator.isEmpty(payload.plans[i].price)) {
          planErrs.price = "Plan price is required";
        }
        if (!validator.isNumeric(payload.plans[i].price)) {
          planErrs.price = "Please enter a valid plan price.";
        }
      }

      for (let k = 0; k < props.length; k++) {
        if (payload.plans[i].properties["p_" + props[k].id]) {
          let val =
            payload.plans[i].properties["p_" + props[k].id][
              props[k].type
            ].toString();

          switch (props[k].type) {
            case "number":
              if (!validator.isInt(val)) {
                propertiesObj["p_" + props[k].id] =
                  "Please enter a valid " + props[k].name;
              }
              break;

            default:
              break;
          }
        } else {
          propertiesObj["p_" + props[k].id] =
            "Please enter a valid " + props[k].name;
        }
      }
      if (
        Object.keys(planErrs).length > 0 ||
        Object.keys(propertiesObj).length > 0
      ) {
        planErros[i] = { ...planErrs, properties: propertiesObj };
      }
    }
    if (planErros.length > 0) errs.plans = planErros;
  } else {
    errs.plan = "At least one Plan is requrired";
  }

  return errs;
};
