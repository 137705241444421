import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";
import { toast } from "react-toastify";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const client = new ApolloClient({
  uri: "/api",
  cache: new InMemoryCache({ addTypename: false }),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
