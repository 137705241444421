import { Router, Route, Switch } from "react-router-dom";
import VendorList from "./pages/public/VendorList";
import VendorDetail from "./pages/public/VendorDetail";
import CreateVendor from "./pages/admin/CreateVendor";
import Properties from "./pages/admin/Properties";
import Vendors from "./pages/admin/Vendors";
import History from "./History";
import CreateProperty from "./pages/admin/CreateProperty";
import UpdateProperty from "./pages/admin/UpdateProperty";
import UpdateVendor from "./pages/admin/UpdateVendor";
import NotFound from "./pages/public/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import { Provider } from "./Context";
import LoginPage from "./pages/public/Login";
import "./App.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";

function App() {
  return (
    <Router history={History}>
      <Provider>
        <Switch>
          <Route exact path="/" component={VendorList} />
          <Route exact path="/detail/:slug" component={VendorDetail} />
          <ProtectedRoute exact path="/admin" component={Vendors} />
          <ProtectedRoute exact path="/admin/vendors" component={Vendors} />
          <ProtectedRoute
            exact
            path="/admin/vendors/create"
            component={CreateVendor}
          />
          <ProtectedRoute
            exact
            path="/admin/properties/create"
            component={CreateProperty}
          />
          <ProtectedRoute
            exact
            path="/admin/vendors/update/:id"
            component={UpdateVendor}
          />
          <ProtectedRoute
            exact
            path="/admin/properties/update/:id"
            component={UpdateProperty}
          />
          <ProtectedRoute exact path="/admin/vendors" component={Vendors} />
          <ProtectedRoute
            exact
            path="/admin/properties"
            component={Properties}
          />
          <Route exact path="/login" component={LoginPage} />
          <Route component={NotFound} />
        </Switch>
      </Provider>
    </Router>
  );
}

export default App;
