const reducer = (state, action) => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        admin: action.payload,
        login_loading: false,
      };
    case "property":
      return {
        ...state,
        property: action.payload,
      };
    case "vendor":
      return {
        ...state,
        vendor: action.payload,
      };
    case "properties":
      return {
        ...state,
        properties: action.payload,
      };
    case "range":
      return {
        ...state,
        range: action.payload,
      };

    case "vendors":
      return {
        ...state,
        vendors: action.payload.vendors,
        vendorCount: action.payload.vendorCount,
      };
    case "filter_prices":
      return {
        ...state,
        filter_prices: action.payload,
      };
    case "prices":
      return {
        ...state,
        prices: action.payload,
      };
    case "input":
      return {
        ...state,
        [action.payload.stateKey]: {
          ...state[action.payload.stateKey],
          ...action.payload.obj,
        },
      };
    default:
      return state;
  }
};
export default reducer;
