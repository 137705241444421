import logo from "../../logo.svg";
import { Container, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "100%",
    background: "#fff",
    marginBottom: "44px",
    marginLeft: 0,
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    minHeight: "101px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    marginLeft: "34px",
    marginTop: 0,
    marginBottom: 0,
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "18px",
    color: "#757575",
  },
}));

function TopBar({ count }) {
  const classes = useStyles();
  return (
    <div className={classes.appBar}>
      <Container>
        <Box className={classes.container}>
          <Box className={classes.container}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Link to="/">
                <img
                  src={logo}
                  className="App-logo"
                  alt="User Feedback Tools"
                  width="221"
                />
              </Link>
            </Box>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.text}
            >
              Compare {count > 0 ? count + "+" : ""} Customer Feedback
              Management Tools' Plans
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default TopBar;
