import React, { useContext } from "react";
import {
  ListItemText,
  ListItem,
  List,
  Hidden,
  Drawer,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Logo from "../../logo.svg";
import { NavLink } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import { Context } from "../../Context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    [theme.breakpoints.up("md")]: { display: "none" },
  },
  main: {
    [theme.breakpoints.down("sm")]: { width: "100%", marginLeft: "0px" },
    width: "calc(100% - 240px)",
    marginLeft: "240px",
  },
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: "#fff",
    padding: "25px 0",
    marginBottom: "50px",
    zIndex: 2,
  },

  drawerPaper: {
    width: drawerWidth,
    border: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const SideBar = (props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { signOut } = useContext(Context);

  const drawer = (
    <div>
      <List>
        <ListItem button>
          <NavLink to="/" className="side-bar-links">
            <img src={Logo} style={{ width: "90%" }} alt="Feedback tools" />
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink to="/admin/vendors" className="side-bar-links">
            <ListItemText primary={"Vendors"} />
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink to="/admin/properties" className="side-bar-links">
            <ListItemText primary={"Properties"} />
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink onClick={signOut} to="/" className="side-bar-links">
            <ListItemText primary={"Sign Out"} />
          </NavLink>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <IconButton
        size="medium"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <DehazeIcon fontSize="large" />
      </IconButton>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.main} style={{ paddingTop: "50px" }}>
        {props.children}
      </main>
    </>
  );
};
export default SideBar;
