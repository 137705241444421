import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Container,
  Paper,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Box,
  Table,
} from "@material-ui/core";
import TopBar from "./TopBar";
import MaterialLink from "@material-ui/core/Link";
import LaunchIcon from "@material-ui/icons/Launch";
import ErrorPage from "../ErrorPage";
import { Context } from "../../Context";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles({
  root: { border: "none", boxShadow: "none" },
  table: {
    minWidth: 650,
  },
  tableRow: {
    borderBottom: "1px solid #EFF1F5",
  },
  tableCell: { height: "43px", padding: 0, borderBottom: "1px solid #EFF1F5" },
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#3E3E3E",
    border: "none",
    verticalAlign: "top",
  },
  tableCellPrice: {
    height: "103px",
    padding: 0,
    borderBottom: "1px solid #EFF1F5",
  },
  regular: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#848484",
    border: "none",
  },
  tableSpan: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",

    /* identical to box height */

    color: "#3E3E3E",
  },
  listPrice: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  listPriceB: {
    fontSize: "18px",
    marginRight: "2px",
    color: "#000000",
  },
  listPriceS: {
    fontSize: "14px",

    color: "#B0B0B0",
  },
  planName: {
    fontStyle: "normal",
    marginBottom: "14px",
    display: "block",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",

    color: "#030303",
  },
});

function DetailPage({ match }) {
  const classes = useStyles();

  const { state, getVendor, vendor_loading } = useContext(Context);

  useEffect(() => {
    getVendor({ variables: { slug: match.params.slug } });
  }, [getVendor, match.params.slug]);

  const getPropertyValue = (plan, id, type) => {
    if (!plan.properties["p_" + id]) return <span>-</span>;
    const property = plan.properties["p_" + id][type];

    let property_text = property;

    if (type === "select") {
      property_text = property_text.replace(/,/g, ", ");
    }

    return property === true ? (
      <CheckIcon style={{ color: "#6FCD36" }} />
    ) : property === false ? (
      <CloseIcon style={{ color: "#B0B0B0" }} />
    ) : (
      <span
        style={{
          fontSize: "14px",
          lineHeight: "16px",
          textAlign: "center",
          color: "#848484",
        }}
      >
        {property_text.length === 0
          ? "-"
          : property_text === "-1"
          ? "Unlimited"
          : property_text}
      </span>
    );
  };

  try {
    return (
      <>
        <TopBar></TopBar>
        <Container>
          <Link to="/" className="back-link">
            <ArrowBackIcon style={{ color: "#868686" }} fontSize="small" />
            <span>Go back</span>
          </Link>
          <div className="detail-card">
            {vendor_loading || state.vendor.name === "" ? (
              <>
                <div className="detail-card-header">
                  <div>
                    <div
                      className="content-wrapper"
                      style={{ width: "95px", marginRight: "15px" }}
                    >
                      <div className="placeholder">
                        <div
                          className="animated-background"
                          style={{ height: "95px" }}
                        ></div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="content-wrapper"
                        style={{ width: "195px", marginBottom: "10px" }}
                      >
                        <div className="placeholder">
                          <div
                            className="animated-background"
                            style={{ height: "10px" }}
                          ></div>
                        </div>
                      </div>
                      <div
                        className="content-wrapper"
                        style={{ width: "195px", marginBottom: "10px" }}
                      >
                        <div className="placeholder">
                          <div
                            className="animated-background"
                            style={{ height: "10px" }}
                          ></div>
                        </div>
                      </div>
                      <div className="content-wrapper">
                        <div className="placeholder">
                          <div
                            className="animated-background"
                            style={{ height: "10px" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <TableContainer component={Paper} className={classes.root}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableCellPrice}
                        >
                          <div className="content-wrapper">
                            <div className="placeholder">
                              <div
                                className="animated-background"
                                style={{ height: "20px" }}
                              ></div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                          <div className="content-wrapper">
                            <div className="placeholder">
                              <div
                                className="animated-background"
                                style={{ height: "20px" }}
                              ></div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : state.vendor ? (
              <>
                <div className="detail-card-header">
                  <div>
                    <img
                      src={"/uploads/" + state.vendor.logo}
                      width="95"
                      alt={state.vendor.name}
                    />
                    <div>
                      <h1>{state.vendor.name}</h1>
                      <a
                        href={state.vendor.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {state.vendor.website}
                      </a>
                      <span>{state.vendor.description}</span>
                    </div>
                  </div>
                  <div className="prices">
                    {state.vendor.plans.length > 0 ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {state.vendor.plans[0].price ? (
                          <b>${state.vendor.plans[0].price}</b>
                        ) : null}

                        {!state.vendor.plans[state.vendor.plans.length - 1]
                          .unkown_price ? (
                          <>
                            <b>-</b>
                            <b>
                              $
                              {
                                state.vendor.plans[
                                  state.vendor.plans.length - 1
                                ].price
                              }
                            </b>
                          </>
                        ) : (
                          <AddIcon />
                        )}
                      </Box>
                    ) : null}
                    <MaterialLink
                      className="pricing-link"
                      href={state.vendor.website}
                      color="inherit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <span>Pricing</span>
                        <LaunchIcon
                          style={{
                            color: "#628EFF",
                            fontSize: "15px",
                            marginLeft: "5px",
                          }}
                        />
                      </Box>
                    </MaterialLink>
                  </div>
                </div>
                {state.vendor.plans.length > 0 ? (
                  <TableContainer component={Paper} className={classes.root}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            <span>Plan</span>
                          </TableCell>
                          {state.vendor.plans.map((plan, index) => (
                            <TableCell
                              key={index}
                              align="center"
                              className={classes.tableCellPrice}
                            >
                              <span className={classes.planName}>
                                {plan.name}
                              </span>
                              <div className={classes.listPrice}>
                                {!plan.unkown_price ? (
                                  <>
                                    <b className={classes.listPriceB}>
                                      ${plan.price}
                                    </b>
                                    <span className={classes.listPriceS}>
                                      /mo
                                    </span>
                                  </>
                                ) : (
                                  <span className={classes.listPriceS}>
                                    Get quote
                                  </span>
                                )}
                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                        {state.properties.map((property, propertyIndex) => (
                          <TableRow
                            key={propertyIndex}
                            className={classes.tableRow}
                          >
                            <TableCell className={classes.tableCell}>
                              <span>{property.name}</span>
                            </TableCell>
                            {state.vendor.plans.map((plan, index) => (
                              <TableCell
                                key={index}
                                align="center"
                                className={classes.tableCell}
                              >
                                {getPropertyValue(
                                  plan,
                                  property.id,
                                  property.type
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </>
            ) : (
              <h1>No Result Found</h1>
            )}
          </div>
        </Container>
      </>
    );
  } catch (error) {
    console.log(error);
    return <ErrorPage></ErrorPage>;
  }
}

export default DetailPage;
