import {
  Container,
  Card,
  CardContent,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import { useRef, useContext, useEffect } from "react";
import { ReactTabulator } from "react-tabulator";
import History from "../../History";
import AddIcon from "@material-ui/icons/Add";
import SideBar from "./SideBar";
import { Context } from "../../Context";
import ErrorPage from "../ErrorPage";

const Properties = () => {
  const tabulatorRef = useRef(null);
  const { state, getProperties, sortProperties } = useContext(Context);

  useEffect(() => {
    getProperties();
  }, [getProperties]);

  const columns = [
    { title: "ID", field: "id", width: 100 },
    {
      title: "Name",
      field: "name",
      headerFilter: true,
      headerFilterPlaceholder: "Filter by name...",
    },
    {
      title: "Type",
      field: "type",
      headerSort: false,
      headerFilter: "select",
      headerFilterPlaceholder: "Select Type",
      headerFilterParams: {
        values: { boolean: "Bool", number: "Number", select: "Select" },
      },
    },
  ];

  try {
    return (
      <SideBar>
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={(e) => History.push("/admin/properties/create")}
            >
              Add New
            </Button>
            <Typography variant="h4">Properties</Typography>
          </Box>
          <Card style={{ marginTop: "30px" }}>
            <CardContent>
              <ReactTabulator
                ref={tabulatorRef}
                columns={columns}
                layout={"fitColumns"}
                rowClick={(e, row) => {
                  History.push(`/admin/properties/update/${row.getData().id}`);
                }}
                options={{
                  movableRows: true,
                  rowMoved: function (_) {
                    const rows = tabulatorRef.current.table.getRows(true);
                    let sortPayload = [];
                    for (let i = 0; i < rows.length; i++) {
                      const row = rows[i];
                      let id = row.getData().id;
                      let index = row.getPosition(false) + 1;
                      sortPayload.push({ id, index });
                    }

                    sortProperties({ variables: { sortPayload } });
                  },
                }}
                data={state.properties}
              />
            </CardContent>
          </Card>
        </Container>
      </SideBar>
    );
  } catch (error) {
    console.log(error);
    return <ErrorPage></ErrorPage>;
  }
};
export default Properties;
