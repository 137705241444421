import React, { useState, useContext, useEffect } from "react";
import {
  Select,
  MenuItem,
  CardContent,
  CardActions,
  Card,
  Container,
  Grid,
  TextField,
  InputLabel,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import SideBar from "./SideBar";
import { propertyValidation } from "../../validation";
import { Context } from "../../Context";
import ErrorPage from "../ErrorPage";

const UpdateProperty = ({ match }) => {
  const [errors, setErrors] = useState({});
  const { state, getProperty, inputHandler, deleteProperty, updateProperty } =
    useContext(Context);

  useEffect(() => {
    getProperty({ variables: { id: match.params.id } });
  }, [getProperty, match.params.id]);

  try {
    return (
      <SideBar>
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/admin/properties" className="back-link">
              <ArrowBackIcon style={{ color: "#868686" }} fontSize="small" />
              <span>Go back</span>
            </Link>
            <Typography variant="h4">Update Property</Typography>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={(e) =>
                deleteProperty({ variables: { id: match.params.id } })
              }
            >
              Delete
            </Button>
          </Box>
          {state.property ? (
            <Card style={{ marginTop: "30px" }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={state.property.name}
                      onChange={(e) => {
                        inputHandler("property", {
                          name: e.target.value,
                        });
                      }}
                    />
                    <small className="err-small">{errors.name}</small>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Type</InputLabel>
                    <Select value={state.property.type} fullWidth>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="boolean">Bool</MenuItem>
                      <MenuItem value="select">Select</MenuItem>
                    </Select>
                    <small className="err-small">{errors.type}</small>
                  </Grid>
                  {state.property.type === "select" ? (
                    <Grid item xs={12}>
                      <TextField
                        label="Select values"
                        variant="outlined"
                        fullWidth
                        value={
                          state.property.select
                            ? state.property.select.join(",")
                            : ""
                        }
                        onChange={(e) => {
                          inputHandler("property", {
                            select: e.target.value.split(","),
                          });
                        }}
                      />
                      <small className="err-small">{errors.select}</small>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.property.list}
                          onChange={(e) => {
                            inputHandler("property", {
                              list: e.target.checked,
                            });
                          }}
                        />
                      }
                      label="Show in list"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={(e) => {
                    let errs = propertyValidation(state.property);
                    setErrors(errs);
                    if (Object.keys(errs).length > 0) {
                      return;
                    }
                    updateProperty({
                      variables: {
                        input: {
                          name: state.property.name,
                          list: state.property.list,
                          type: state.property.type,
                          select: state.property.select,
                        },
                        id: match.params.id,
                      },
                    });
                  }}
                >
                  UPDATE
                </Button>
              </CardActions>
            </Card>
          ) : null}
        </Container>
      </SideBar>
    );
  } catch (error) {
    console.log(error);
    return <ErrorPage></ErrorPage>;
  }
};

export default UpdateProperty;
