import { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Context } from "./Context";

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  const { state } = useContext(Context);

  if (state.login_loading) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (state.admin) {
          return <Component {...rest} {...props} />;
        } else {
          return <Redirect to={"/login"} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
