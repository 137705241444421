import React, { useState, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Switch,
  Button,
  Slider,
  Box,
  MenuItem,
  Select as MaterialSelect,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TopBar from "./TopBar";
import Select from "react-select";
import LaunchIcon from "@material-ui/icons/Launch";
import ErrorPage from "../ErrorPage";
import { Context } from "../../Context";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ClearFilterIcon from "../../clear_fliters.svg";

const useStyles = makeStyles({
  select: {
    marginLeft: "5px",
    fontSize: "14px",
    paddingTop: "2px",
    color: "#828282",
    backgroundColor: "#fff",
  },
  dropdownStyle: {
    color: "#828282",
    fontSize: "14px",
    borderRadius: "4px",
  },
  clearBtn: {
    minWidth: "16px",
    padding: "6px 0",
  },
  clearBtnText: { padding: 0 },
});

const RangeSlider = withStyles({
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid #628EFF",
    marginTop: -6,
    marginLeft: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  root: { padding: 0 },
  track: { backgroundColor: "#628EFF", height: 4, borderRadius: 4 },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: "#CACEDB",
  },
})(Slider);

const FilterSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 21,
    margin: "0",
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#628EFF",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: { width: 16, height: 16 },
  track: {
    borderRadius: "35px",
    backgroundColor: "#CACEDB",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ListPage = () => {
  const classes = useStyles();
  //const [range, setRange] = useState({});
  //const [prices, setPrices] = useState({});
  const [sort, setSort] = useState("DEFAULT");
  const [showFilters, setShowFilters] = useState(false);

  const {
    state,
    getVendors,
    getProperties,
    vendors_loading,
    properties_loading,
    setPrices,
    setRange,
  } = useContext(Context);

  useEffect(() => {
    getVendors({ variables: { filters: state.range, sort } });
    getProperties();
  }, []);

  const updateFilters = (payload, srt) => {
    setRange(payload);
    setSort(srt);
    getVendors({ variables: { filters: payload, sort: srt } });
  };

  const clearFilters = () => {
    setRange({});
    setPrices({});
    getVendors({ variables: { filters: {}, sort } });
  };

  try {
    return (
      <>
        <TopBar count={state.vendorCount}></TopBar>
        <Container>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12} md={3}>
              <div className="filter-title">
                <h6>Filter Plans</h6>
                <button
                  className="toggle-filter"
                  onClick={(e) => setShowFilters(!showFilters)}
                >
                  <h6>Filter Plans</h6>
                  {showFilters ? (
                    <KeyboardArrowUpIcon fontSize={"small"} />
                  ) : (
                    <KeyboardArrowDownIcon fontSize={"small"} />
                  )}
                </button>
                <Button
                  classes={{
                    root: classes.clearBtn,
                    text: classes.clearBtnText,
                  }}
                  onClick={clearFilters}
                >
                  <img src={ClearFilterIcon} alt="Clear Filters" width="16" />
                </Button>
              </div>
              {!properties_loading && state.properties.length > 0 ? (
                <>
                  <div
                    className={
                      showFilters ? "filter-show filter-list" : "filter-list"
                    }
                  >
                    <div className="list-slider">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>Price</span>
                        <small>
                          up to
                          {state.prices.max_price
                            ? parseInt(state.prices.max_price) ===
                              parseInt(state.filter_prices.max_price)
                              ? " Unlimited"
                              : " $" + state.prices.max_price
                            : " Unlimited"}
                        </small>
                      </Box>
                      <RangeSlider
                        value={
                          state.prices.max_price
                            ? state.prices.max_price
                            : state.filter_prices.max_price
                        }
                        onChange={(e, newValue) => {
                          setPrices({
                            ...state.filter_prices,
                            max_price: newValue,
                          });
                        }}
                        onChangeCommitted={(e, newValue) => {
                          if (newValue === state.filter_prices.max_price) {
                            newValue = "unlimited";
                          }
                          updateFilters(
                            { ...state.range, price: newValue },
                            sort
                          );
                        }}
                        min={state.filter_prices.min_price}
                        max={state.filter_prices.max_price}
                      />
                    </div>
                    {state.properties.map((property, index) => {
                      return (
                        <div
                          className={
                            property.type === "boolean"
                              ? "list-switch"
                              : property.type === "select"
                              ? "list-select"
                              : "list-slider"
                          }
                          key={index}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <span>{property.name}</span>
                            {property.type === "number" ? (
                              <small>
                                {state.range[property.id]
                                  ? state.range[property.id] ===
                                    property.max + 1
                                    ? "Unlimited"
                                    : `${state.range[property.id]}`
                                  : `${
                                      property.min === -1
                                        ? "Unlimited"
                                        : property.min
                                    }`}
                              </small>
                            ) : null}
                          </Box>

                          {property.type === "boolean" ? (
                            <FilterSwitch
                              checked={
                                state.range[property.id]
                                  ? state.range[property.id]
                                  : false
                              }
                              onChange={(e) => {
                                updateFilters(
                                  {
                                    ...state.range,
                                    [property.id]: e.target.checked,
                                  },
                                  sort
                                );
                              }}
                            />
                          ) : property.type === "select" ? (
                            <Select
                              isMulti
                              className="select"
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  boxShadow: "none",
                                  border: "none",
                                }),
                              }}
                              value={
                                state.range[property.id]
                                  ? state.range[property.id].map((a) => ({
                                      value: a,
                                      label: a,
                                    }))
                                  : []
                              }
                              onChange={(selects) => {
                                updateFilters(
                                  {
                                    ...state.range,
                                    [property.id]: selects.map((a) => a.value),
                                  },
                                  sort
                                );
                              }}
                              options={property.select.map((a) => ({
                                value: a,
                                label: a,
                              }))}
                            />
                          ) : (
                            <>
                              <RangeSlider
                                value={state.range[property.id] || property.min}
                                onChange={(e, newValue) => {
                                  if (property.min !== property.max)
                                    setRange({
                                      ...state.range,
                                      [property.id]: newValue,
                                    });
                                }}
                                onChangeCommitted={(e, newValue) => {
                                  if (property.min !== property.max)
                                    updateFilters(
                                      {
                                        ...state.range,
                                        [property.id]: newValue,
                                      },
                                      sort
                                    );
                                }}
                                min={property.min}
                                max={
                                  property.unlimited
                                    ? property.max + 1
                                    : property.max
                                }
                                aria-labelledby="continuous-slider"
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <ul>
                  <li style={{ marginBottom: "25px" }}>
                    <div className="content-wrapper">
                      <div className="placeholder">
                        <div
                          className="animated-background"
                          style={{ height: "20px" }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li style={{ marginBottom: "25px" }}>
                    <div className="content-wrapper">
                      <div className="placeholder">
                        <div
                          className="animated-background"
                          style={{ height: "20px" }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li style={{ marginBottom: "25px" }}>
                    <div className="content-wrapper">
                      <div className="placeholder">
                        <div
                          className="animated-background"
                          style={{ height: "20px" }}
                        ></div>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={9}>
              <div className="list-container">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="26px"
                >
                  <span className="result-span">
                    Showing {state.vendors.length} Results
                  </span>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="sort-span"> Sort: </span>
                    <MaterialSelect
                      value={sort}
                      disableUnderline
                      IconComponent={KeyboardArrowDownIcon}
                      MenuProps={{
                        classes: {
                          paper: classes.dropdownStyle,
                        },
                      }}
                      className={classes.select}
                      onChange={(e) => {
                        updateFilters(state.range, e.target.value);
                      }}
                    >
                      <MenuItem
                        className={classes.dropdownStyle}
                        value={"DEFAULT"}
                      >
                        Default
                      </MenuItem>
                      <MenuItem className={classes.dropdownStyle} value={"ASC"}>
                        Price ASC
                      </MenuItem>
                      <MenuItem
                        className={classes.dropdownStyle}
                        value={"DESC"}
                      >
                        Price DESC
                      </MenuItem>
                    </MaterialSelect>
                  </Box>
                </Box>
                {state.vendors.length > 0 ? (
                  <ul>
                    {state.vendors.map((vendor, index) => (
                      <li key={index}>
                        <Link to={`/detail/${vendor.slug}`}>
                          <div className="list-vendor">
                            <img
                              src={"/uploads/" + vendor.logo}
                              alt={vendor.name}
                            />
                            <div>
                              <h3>{vendor.name}</h3>
                              <span>{vendor.selected_name} Plan</span>
                            </div>
                          </div>

                          {state.properties.map((property, propertyIndex) => {
                            if (property.list) {
                              if (
                                vendor.selected_properties["p_" + property.id]
                              ) {
                                let val =
                                  vendor.selected_properties[
                                    "p_" + property.id
                                  ][property.type];
                                switch (property.type) {
                                  case "number":
                                  case "select":
                                    return (
                                      <span key={propertyIndex}>
                                        {val === "-1"
                                          ? "Unlimited " + property.name
                                          : val + " " + property.name}
                                      </span>
                                    );
                                  case "boolean":
                                    if (val)
                                      return (
                                        <span key={propertyIndex}>
                                          {property.name}
                                        </span>
                                      );
                                    break;
                                  default:
                                    break;
                                }
                              }
                            }
                            return null;
                          })}

                          <div
                            className="list-price"
                            style={{ width: "100px" }}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {!vendor.unkown_price ? (
                                <>
                                  <b>${vendor.selected_price}</b>
                                  <span className="list-price-span">/mo</span>
                                </>
                              ) : (
                                <span className="list-price-span">
                                  Get quote
                                </span>
                              )}
                            </Box>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : vendors_loading ? (
                  <ul>
                    <li>
                      <div className="content-wrapper">
                        <div className="placeholder">
                          <div className="animated-background"></div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="content-wrapper">
                        <div className="placeholder">
                          <div className="animated-background"></div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="content-wrapper">
                        <div className="placeholder">
                          <div className="animated-background"></div>
                        </div>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <span>No result found</span>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  } catch (error) {
    console.log(error);
    return <ErrorPage></ErrorPage>;
  }
};

export default ListPage;

/*<span
                              className="pricing-link"
                              onClick={(e) => {
                                e.preventDefault();
                                window
                                  .open(state.vendor.website, "_blank")
                                  .focus();
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <span>Pricing</span>
                                <LaunchIcon
                                  style={{
                                    color: "#628EFF",
                                    fontSize: "15px",
                                    marginRight: "5px",
                                  }}
                                />
                              </Box>
                            </span>*/
