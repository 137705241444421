import React, { useState, useContext, useEffect } from "react";
import {
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  Table,
  Paper,
  Grid,
  Container,
  Button,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  Typography,
  TextField,
  Box,
  FormControlLabel,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import SideBar from "./SideBar";
import Select from "react-select";
import { vendorValidation } from "../../validation";
import { Context } from "../../Context";
import ErrorPage from "../ErrorPage";
import IconButton from "@material-ui/core/IconButton";

const UpdateVendor = ({ match }) => {
  const [planName, setPlanName] = useState("");
  const [planPrice, setPlanPrice] = useState("");
  const [errors, setErrors] = useState({});
  const [unkownPrice, setUnkownPrice] = useState(false);

  const {
    state,
    getVendor,
    updateVendor,
    inputHandler,
    deleteVendor,
    addNewPlan,
    updatePlan,
    updatePlanName,
    getRootProps,
    updatePlanPrice,
    getInputProps,
    deletePlan,
  } = useContext(Context);

  useEffect(() => {
    getVendor({ variables: { id: match.params.id } });
  }, [getVendor, match.params.id]);

  try {
    return (
      <SideBar>
        <Container>
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Link to="/admin/vendors" className="back-link">
              <ArrowBackIcon style={{ color: "#868686" }} fontSize="small" />
              <span>Go back</span>
            </Link>
            <Typography variant="h4">Update Property</Typography>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={(e) =>
                deleteVendor({ variables: { id: match.params.id } })
              }
            >
              Delete
            </Button>
          </Box>
          <Card style={{ marginTop: "30px" }}>
            {state.vendor ? (
              <>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        label="Vendor Name"
                        variant="outlined"
                        fullWidth
                        value={state.vendor.name}
                        onChange={(e) => {
                          inputHandler("vendor", {
                            name: e.target.value,
                          });
                        }}
                      />
                      <small className="err-small">{errors.name}</small>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Vendor Website"
                        fullWidth
                        variant="outlined"
                        value={state.vendor.website}
                        onChange={(e) => {
                          inputHandler("vendor", {
                            website: e.target.value,
                          });
                        }}
                      />
                      <small className="err-small">{errors.website}</small>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Vendor Description"
                        fullWidth
                        variant="outlined"
                        value={state.vendor.description}
                        onChange={(e) => {
                          inputHandler("vendor", {
                            description: e.target.value,
                          });
                        }}
                      />
                      <small className="err-small">{errors.description}</small>
                    </Grid>
                    <Grid item xs={12}>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                      <small className="err-small">{errors.logo}</small>
                      <aside className="upload-thumbsContainer">
                        {state.vendor.logo.trim() !== "" ? (
                          <div className="upload-thumb">
                            <div className={"upload-thumbInner"}>
                              <img
                                src={"/uploads/" + state.vendor.logo}
                                className="upload-img"
                                alt="preview"
                              />
                            </div>
                          </div>
                        ) : null}
                      </aside>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Plan Name"
                        variant="outlined"
                        fullWidth
                        value={planName}
                        onChange={(e) => {
                          setPlanName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Plan Price"
                        variant="outlined"
                        maxLength="10"
                        value={unkownPrice ? "" : planPrice}
                        fullWidth
                        disabled={unkownPrice}
                        onChange={(e) => {
                          setPlanPrice(e.target.value);
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={unkownPrice}
                            onChange={(e) => {
                              setUnkownPrice(e.target.checked);
                              if (e.target.checked) {
                                setPlanPrice("0");
                              } else {
                                setPlanPrice("");
                              }
                            }}
                          />
                        }
                        label="Ask for price"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        size="small"
                        fullWidth
                        onClick={(e) => {
                          addNewPlan(planName, planPrice, unkownPrice);
                          setUnkownPrice(false);
                          setPlanName("");
                          setPlanPrice("");
                        }}
                        color="secondary"
                        variant="contained"
                      >
                        Add Plan
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {state.loading ? null : (
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableBody>
                              <TableRow>
                                <TableCell>Plan</TableCell>
                                {state.vendor.plans.map((plan, index) => (
                                  <TableCell key={index} align="center">
                                    <IconButton
                                      aria-label="delete"
                                      onClick={(e) => deletePlan(index)}
                                      color="secondary"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <Box display="flex" flexDirection="column">
                                      <TextField
                                        onChange={(e) => {
                                          updatePlanName(e.target.value, index);
                                        }}
                                        maxLength="10"
                                        value={plan.name}
                                        label={"Plan name"}
                                      />
                                      <TextField
                                        onChange={(e) => {
                                          updatePlanPrice(
                                            e.target.value,
                                            index,
                                            false
                                          );
                                        }}
                                        type="number"
                                        maxLength="10"
                                        value={
                                          plan.unkown_price ? "" : plan.price
                                        }
                                        label={"Plan Price"}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={plan.unkown_price}
                                            onChange={(e) => {
                                              updatePlanPrice(
                                                e.target.checked,
                                                index,
                                                true
                                              );
                                            }}
                                          />
                                        }
                                        label="Ask for price"
                                      />
                                    </Box>
                                  </TableCell>
                                ))}
                              </TableRow>
                              {state.properties.map(
                                (property, propertyIndex) => {
                                  return (
                                    <TableRow key={propertyIndex}>
                                      <TableCell>{property.name}</TableCell>
                                      {state.vendor.plans.map(
                                        (plan, planIndex) => {
                                          let cellValue = "";
                                          if (
                                            plan.properties["p_" + property.id]
                                          ) {
                                            cellValue =
                                              plan.properties[
                                                "p_" + property.id
                                              ][property.type];
                                          } else {
                                            if (property.type === "boolean") {
                                              updatePlan(
                                                false,
                                                planIndex,
                                                property.id,
                                                property.type
                                              );
                                            }
                                          }
                                          return (
                                            <TableCell
                                              key={planIndex}
                                              align="center"
                                            >
                                              {property.type === "boolean" ? (
                                                <Checkbox
                                                  onChange={(e) => {
                                                    updatePlan(
                                                      e.target.checked,
                                                      planIndex,
                                                      property.id,
                                                      property.type
                                                    );
                                                  }}
                                                  checked={cellValue}
                                                />
                                              ) : property.type === "select" ? (
                                                <Select
                                                  isMulti
                                                  className="select"
                                                  styles={{
                                                    control: (base) => ({
                                                      ...base,
                                                      boxShadow: "none",
                                                      border: "none",
                                                    }),
                                                  }}
                                                  onChange={(selects) => {
                                                    updatePlan(
                                                      selects
                                                        .map((a) => a.value)
                                                        .join(","),
                                                      planIndex,
                                                      property.id,
                                                      property.type
                                                    );
                                                  }}
                                                  defaultValue={cellValue
                                                    .split(",")
                                                    .filter(
                                                      (a) => a.trim() !== ""
                                                    )
                                                    .map((a) => ({
                                                      value: a,
                                                      label: a,
                                                    }))}
                                                  options={property.select.map(
                                                    (a) => ({
                                                      value: a,
                                                      label: a,
                                                    })
                                                  )}
                                                />
                                              ) : (
                                                <Box
                                                  display="flex"
                                                  alignItems="center"
                                                  flexDirection="column"
                                                >
                                                  <TextField
                                                    onChange={(e) => {
                                                      updatePlan(
                                                        e.target.value,
                                                        planIndex,
                                                        property.id,
                                                        property.type
                                                      );
                                                    }}
                                                    maxLength="10"
                                                    value={
                                                      cellValue === "-1"
                                                        ? ""
                                                        : cellValue
                                                    }
                                                    label={`${property.name} for ${plan.name}`}
                                                  />
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        checked={
                                                          cellValue === "-1"
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) => {
                                                          updatePlan(
                                                            e.target.checked
                                                              ? "-1"
                                                              : "",
                                                            planIndex,
                                                            property.id,
                                                            property.type
                                                          );
                                                        }}
                                                      />
                                                    }
                                                    label="Unlimited"
                                                  />
                                                  <small className="err-small">
                                                    {errors.plans
                                                      ? errors.plans[planIndex]
                                                        ? errors.plans[
                                                            planIndex
                                                          ].properties[
                                                            "p_" + property.id
                                                          ]
                                                        : null
                                                      : null}
                                                  </small>
                                                </Box>
                                              )}
                                            </TableCell>
                                          );
                                        }
                                      )}
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={(e) => {
                      let errs = vendorValidation(
                        state.vendor,
                        state.properties
                      );
                      setErrors(errs);
                      if (Object.keys(errs).length > 0) {
                        return;
                      }
                      updateVendor({
                        variables: {
                          input: state.vendor,
                          id: match.params.id,
                        },
                      });
                    }}
                  >
                    Update
                  </Button>
                </CardActions>
              </>
            ) : null}
          </Card>
        </Container>
      </SideBar>
    );
  } catch (error) {
    console.log(error);
    return <ErrorPage></ErrorPage>;
  }
};

export default UpdateVendor;
/* {!plan.unkown_price ? (
                                      <div className="list-price">
                                        <b>${plan.price}</b>
                                        <span>/mo</span>
                                      </div>
                                    ) : null}*/
