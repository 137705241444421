import React, { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import Reducer from "./Reducer";
import { toast } from "react-toastify";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import History from "./History";
import ErrorPage from "./pages/ErrorPage";
import { useDropzone } from "react-dropzone";
import validator from "validator";
import jwt from "jsonwebtoken";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// Initial state
const initialState = {
  admin: null,
  hasError: false,
  errors: {},
  properties: [],
  vendors: [],
  vendor: { name: "", website: "", logo: "", description: "", plans: [] },
  property: { name: "", type: "", list: false, select: [] },
  sort: "ASC",
  filters: {},
  prices: {},
  filter_prices: {},
  login_loading: true,
  range: {},
  vendorCount: 0,
};

//queries
const LOGIN = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      token
    }
  }
`;

const VENDORS = gql`
  query vendors($filters: JSON, $sort: SortEnum) {
    vendors(filters: $filters, sort: $sort) {
      id
      name
      slug
      logo
      selected_name
      selected_price
      selected_properties
      unkown_price
    }
    vendorCount
  }
`;

const VENDOR = gql`
  query Vendor($slug: String, $id: ID) {
    vendor(slug: $slug, id: $id) {
      name
      logo
      website
      description
      plans {
        name
        price
        unkown_price
        properties
      }
    }
    properties {
      properties {
        id
        name
        select
        type
        min
        max
        slug
        unlimited
        list
      }
    }
  }
`;

const PROPERTIES = gql`
  query {
    properties {
      properties {
        id
        name
        select
        type
        min
        max
        slug
        unlimited
        list
      }
      prices {
        min_price
        max_price
      }
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: PropertyInput!, $id: ID!) {
    updateProperty(input: $input, id: $id) {
      name
    }
  }
`;

const DELETE_PROPERTY = gql`
  mutation deleteProperty($id: ID!) {
    deleteProperty(id: $id) {
      name
    }
  }
`;

const DELETE_VENDOR = gql`
  mutation deleteVendor($id: ID!) {
    deleteVendor(id: $id) {
      name
    }
  }
`;

const CREATE_PROPERTY = gql`
  mutation createProperty($input: PropertyInput!) {
    createProperty(input: $input) {
      id
      name
    }
  }
`;

const CREATE_VENDOR = gql`
  mutation createVendor($input: VendorInput!) {
    createVendor(input: $input) {
      id
      name
    }
  }
`;

const UPDATE_VENDOR = gql`
  mutation updateVendor($input: VendorInput!, $id: ID!) {
    updateVendor(input: $input, id: $id) {
      id
      name
    }
  }
`;

const PROPERTY = gql`
  query property($id: ID!) {
    property(id: $id) {
      id
      name
      select
      type
      min
      max
      slug
      unlimited
      list
    }
  }
`;

const SORT_PROPERTIES = gql`
  mutation sortProperties($sortPayload: JSON!) {
    sortProperties(sortPayload: $sortPayload) {
      success
    }
  }
`;

const SORT_VENDORS = gql`
  mutation sortVendors($sortPayload: JSON!) {
    sortVendors(sortPayload: $sortPayload) {
      success
    }
  }
`;
//queries

// Create context
export const Context = createContext(initialState);

// Provider component
export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    const admin = cookies.get("authorization");
    if (admin) {
      dispatch({ type: "login", payload: jwt.decode(admin) });
    } else {
      dispatch({ type: "login", payload: null });
    }
  }, []);

  const signOut = (e) => {
    e.preventDefault();
    cookies.remove("authorization", { path: "/" });
    window.location.reload();
  };

  const inputHandler = (stateKey, obj) => {
    dispatch({ type: "input", payload: { stateKey, obj } });
  };

  const [adminLogin] = useLazyQuery(LOGIN, {
    fetchPolicy: "no-cache",
    onError: (e) => {
      dispatch({ type: "hasError", payload: true });
    },
    onCompleted: (data) => {
      if (data.login.success) {
        dispatch({ type: "login", payload: jwt.decode(data.login.token) });
        toast.success("Login Successful");
        History.push("/admin");
        return;
      }
      toast.error("Wrong Login Credentials!");
    },
  });

  const [getVendors, { loading: vendors_loading, refetch: getVendorsRefetch }] =
    useLazyQuery(VENDORS, {
      onError: (e) => {
        dispatch({ type: "hasError", payload: true });
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data) {
          dispatch({
            type: "vendors",
            payload: { vendors: data.vendors, vendorCount: data.vendorCount },
          });
        }
      },
    });

  const [getVendor, { loading: vendor_loading, refetch: getVendorRefetch }] =
    useLazyQuery(VENDOR, {
      onError: (e) => {
        dispatch({ type: "hasError", payload: true });
      },
      onCompleted: (data) => {
        dispatch({ type: "vendor", payload: data.vendor });
        dispatch({ type: "properties", payload: data.properties.properties });
      },
    });

  const [
    getProperties,
    { loading: properties_loading, refetch: getPropertiesRefecth },
  ] = useLazyQuery(PROPERTIES, {
    onError: (e) => {
      dispatch({ type: "hasError", payload: true });
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        dispatch({ type: "filter_prices", payload: data.properties.prices });
        dispatch({ type: "properties", payload: data.properties.properties });
      }
    },
  });

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: () => {
      getPropertiesRefecth();
      toast.success("Property successfully updated!");
      setTimeout(() => {
        History.goBack();
      }, 500);
    },
  });

  const [createProperty] = useMutation(CREATE_PROPERTY, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: () => {
      getPropertiesRefecth();
      toast.success("Property successfully created!");
      setTimeout(() => {
        History.goBack();
      }, 500);
    },
  });

  const [deleteProperty] = useMutation(DELETE_PROPERTY, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: (b) => {
      getPropertiesRefecth();
      toast.success("Property successfully deleted!");
      setTimeout(() => {
        History.goBack();
      }, 500);
    },
  });

  const [deleteVendor] = useMutation(DELETE_VENDOR, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: () => {
      getVendorsRefetch();
      toast.success("Vendor successfully deleted!");
      setTimeout(() => {
        History.goBack();
      }, 500);
    },
  });

  const [getProperty] = useLazyQuery(PROPERTY, {
    onError: (err) => {
      dispatch({ type: "hasError", payload: true });
    },
    onCompleted: (data) => {
      dispatch({ type: "property", payload: data.property });
    },
  });

  const [createVendor] = useMutation(CREATE_VENDOR, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: () => {
      getVendorsRefetch();
      toast.success("Property successfully created!");
      setTimeout(() => {
        History.goBack();
      }, 500);
    },
  });

  const [sortProperties] = useMutation(SORT_PROPERTIES, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: (data) => {
      getPropertiesRefecth();
      if (data.sortProperties.success) {
        toast.success("Properties successfully sorted!");
      } else {
        toast.error("Someting went wrong :/");
      }
    },
  });

  const [sortVendors] = useMutation(SORT_VENDORS, {
    onError: (err) => {
      toast.error(err.message);
    },
    onCompleted: (data) => {
      getVendorsRefetch();
      if (data.sortVendors.success) {
        toast.success("Vendors successfully sorted!");
      } else {
        toast.error("Someting went wrong :/");
      }
    },
  });

  const [updateVendor] = useMutation(UPDATE_VENDOR, {
    onError: (err) => {
      console.log(err);
      toast.error(err.message);
    },
    onCompleted: (data) => {
      getVendorRefetch({ variables: { id: data.updateVendor.id } });
      toast.success("Property successfully updated!");
      setTimeout(() => {
        History.goBack();
      }, 500);
    },
  });

  const clearState = async () => {
    dispatch({ type: "property", payload: initialState.property });
    dispatch({ type: "vendor", payload: initialState.vendor });
  };

  const addNewPlan = (planName, planPrice, unkownPrice) => {
    try {
      if (validator.isEmpty(planName)) {
        toast.error("Please enter a plan name");
        return;
      }
      if (unkownPrice === false) {
        if (!validator.isNumeric(planPrice)) {
          toast.error("Please enter a valid plan price");
          return;
        }
        if (planPrice < 0) {
          toast.error("Please enter a valid plan price");
          return;
        }
      }

      const properties = {};
      for (let i = 0; i < state.properties.length; i++) {
        let type = state.properties[i].type;
        properties["p_" + state.properties[i].id] = {
          [type]: type === "boolean" ? false : "",
        };
      }

      dispatch({
        type: "input",
        payload: {
          stateKey: "vendor",
          obj: {
            plans: [
              ...state.vendor.plans,
              {
                name: planName,
                unkown_price: unkownPrice,
                price: planPrice,
                properties,
              },
            ],
          },
        },
      });
    } catch (error) {
      dispatch({ type: "hasError", payload: true });
    }
  };

  const updatePlanName = (value, planIndex) => {
    let new_plans = state.vendor.plans;
    new_plans[planIndex].name = value;
    dispatch({
      type: "vendor",
      payload: { ...state.vendor, plans: new_plans },
    });
  };

  const updatePlanPrice = (value, planIndex, unkown) => {
    let new_plans = state.vendor.plans;
    if (unkown) {
      if (value) {
        new_plans[planIndex].price = "0";
      } else {
        new_plans[planIndex].price = "";
      }
      new_plans[planIndex].unkown_price = value;
    } else {
      new_plans[planIndex].price = value;
      new_plans[planIndex].unkown_price = false;
    }

    dispatch({
      type: "vendor",
      payload: { ...state.vendor, plans: new_plans },
    });
  };

  const updatePlan = (value, planIndex, id, type) => {
    try {
      const newPlans = state.vendor.plans;
      if (newPlans[planIndex].properties["p_" + id]) {
        newPlans[planIndex].properties["p_" + id][type] = value;
      } else {
        newPlans[planIndex].properties["p_" + id] = { [type]: value };
      }

      dispatch({
        type: "input",
        payload: { stateKey: "vendor", obj: { plans: newPlans } },
      });
    } catch (error) {
      dispatch({ type: "hasError", payload: true });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",

    onDrop: async (acceptedFiles) => {
      try {
        const fileForm = new FormData();
        fileForm.append("image", acceptedFiles[0]);
        const res = await axios.post("/upload", fileForm);
        if (res.data.success)
          dispatch({
            type: "input",
            payload: { stateKey: "vendor", obj: { logo: res.data.image } },
          });
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.msg);
        } else {
          toast.error(error.message);
        }
      }
    },
  });

  const deletePlan = (index) => {
    let new_plans = state.vendor.plans;
    new_plans.splice(index, 1);
    dispatch({
      type: "vendor",
      payload: { ...state.vendor, plans: new_plans },
    });
  };

  const setRange = (payload) => {
    dispatch({ type: "range", payload });
  };
  const setPrices = (payload) => {
    dispatch({ type: "prices", payload });
  };

  if (state.hasError) return <ErrorPage></ErrorPage>;
  return (
    <Context.Provider
      value={{
        state,
        getVendors,
        getVendor,
        getProperties,
        deleteProperty,
        updateProperty,
        signOut,
        createProperty,
        vendors_loading,
        getProperty,
        adminLogin,
        inputHandler,
        addNewPlan,
        updatePlan,
        getRootProps,
        vendor_loading,
        getInputProps,
        properties_loading,
        createVendor,
        sortProperties,
        updateVendor,
        deleteVendor,
        clearState,
        deletePlan,
        setRange,
        setPrices,
        updatePlanName,
        updatePlanPrice,
        sortVendors,
      }}
    >
      {children}
    </Context.Provider>
  );
};
