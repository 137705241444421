import React, { useContext, useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  CardContent,
  CardActions,
  Card,
  Container,
  Grid,
  TextField,
  InputLabel,
  Button,
  Checkbox,
  Box,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SideBar from "./SideBar";
import { propertyValidation } from "../../validation";
import { Context } from "../../Context";
import ErrorPage from "../ErrorPage";

const CreateProperty = () => {
  const [errors, setErrors] = useState({});

  const { state, createProperty, inputHandler, clearState } =
    useContext(Context);

  useEffect(() => {
    clearState();
  }, []);

  try {
    return (
      <SideBar>
        <Container>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Link to="/admin/properties" className="back-link">
              <ArrowBackIcon style={{ color: "#868686" }} fontSize="small" />
              <span>Go back</span>
            </Link>
            <Typography variant="h4">Create Property</Typography>
          </Box>
          <Card style={{ marginTop: "30px" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={state.property.name}
                    onChange={(e) => {
                      inputHandler("property", { name: e.target.value });
                    }}
                  />
                  <small className="err-small">{errors.name}</small>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={state.property.type}
                    onChange={(e) => {
                      inputHandler("property", { type: e.target.value });
                    }}
                    fullWidth
                  >
                    <MenuItem value="number">Number</MenuItem>
                    <MenuItem value="boolean">Bool</MenuItem>
                    <MenuItem value="select">Select</MenuItem>
                  </Select>
                  <small className="err-small">{errors.type}</small>
                </Grid>
                {state.property.type === "select" ? (
                  <Grid item xs={12}>
                    <TextField
                      label="Select Fields"
                      variant="outlined"
                      fullWidth
                      value={state.property.select.join(",")}
                      onChange={(e) => {
                        inputHandler("property", {
                          select: e.target.value.split(","),
                        });
                      }}
                    />
                    <small className="err-small">{errors.select}</small>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={state.property.list}
                      onChange={(e) => {
                        inputHandler("property", { list: e.target.checked });
                      }}
                    />
                  }
                  label="Show in list"
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={(e) => {
                  let errs = propertyValidation(state.property);
                  setErrors(errs);
                  if (Object.keys(errs).length > 0) {
                    return;
                  }
                  if (state.property.type !== "select") {
                    delete state.property.select;
                  }
                  createProperty({
                    variables: { input: state.property },
                  });
                }}
              >
                Add
              </Button>
            </CardActions>
          </Card>
        </Container>
      </SideBar>
    );
  } catch (error) {
    console.log(error);
    return <ErrorPage></ErrorPage>;
  }
};

export default CreateProperty;
